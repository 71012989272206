import { useLazyQuery } from '@apollo/client';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { REFRESH_TOKEN_QUERY } from '../../../graphql/auth';
import { AppDispatch, RootState, useAppDispatch, useTypedSelector } from '../../../store';
import { AuthResult, setAuthData } from '../../../store/auth/auth-slice';

const RefreshTokenButton = () => {
    const navigate = useNavigate();
    const [RefreshToken, { loading, error, data }] = useLazyQuery(REFRESH_TOKEN_QUERY);
    const dispatch: AppDispatch = useAppDispatch();
    const authRefreshToken = useTypedSelector((state: RootState) => state.auth.info?.RefreshToken);

    const handleRefresh = async () => {
        try {
            const response = await RefreshToken({
                variables: { refreshTokenReq: authRefreshToken }
            });

            const newSigninData: AuthResult = {
                ...response.data?.refreshToken,
                AuthenticationResult: {
                    ...response.data?.refreshToken.AuthenticationResult,
                    RefreshToken: authRefreshToken
                }
            };

            dispatch(setAuthData(newSigninData));
            navigate(0);
        } catch (err) {
            console.error('Errore:', err);
        }
    };

    return (
        <Button variant="contained" id="refreshToken" type="button" onClick={() => handleRefresh()}>
            Refresh Token
        </Button>
    );
};

export default RefreshTokenButton;
