import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from 'react';

const TokenExpirationCounter = ({ token }: { token: string }) => {
    const [timeRemaining, setTimeRemaining] = useState<{ hours: number; minutes: number; seconds: number }>({ hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const calculateTimeRemaining = () => {
            const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
            const expirationTimeStamp: number = jwtDecode(token).exp || 0;
            const timeRemainingInSecond = expirationTimeStamp - currentTimestampInSeconds;

            if (timeRemainingInSecond > 0) {
                const hours = Math.floor(timeRemainingInSecond / 3600);
                const minutes = Math.floor((timeRemainingInSecond % 3600) / 60);
                const seconds = timeRemainingInSecond % 60;

                setTimeRemaining({ hours: hours, minutes: minutes, seconds: seconds });
            } else {
                setTimeRemaining({ hours: 0, minutes: 0, seconds: 0 });
            }
        };

        const expiredSeconds = jwtDecode(token).exp || 0;
        if (expiredSeconds) calculateTimeRemaining();
        const intervalId = setInterval(calculateTimeRemaining, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div>
            Tempo rimanente: {timeRemaining.hours} ore, {timeRemaining.minutes} minuti, {timeRemaining.seconds} secondi{' '}
        </div>
    );
};

export default TokenExpirationCounter;
