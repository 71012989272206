import { Theme, createTheme } from '@mui/material';

const MUITheme: Theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#3f51b5'
        },
        secondary: {
            main: '#f50057'
        }
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    '&[disabled]': {
                        color: 'grey',
                        opacity: 0.5
                    }
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#3f51b5',
                    fontSize: '14px'
                },
                arrow: {
                    color: '#3f51b5'
                }
            }
        },
        MuiTextField: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: 'aliceblue',
                        ':hover': {
                            '.MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3f51b5 !important'
                            }
                        }
                    }
                }
            ]
        },
        MuiInputBase: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        ':hover': {
                            '> .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3f51b5 !important'
                            }
                        }
                        //border: '1px solid #333'
                    }
                }
            ]
        },
        MuiOutlinedInput: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: {
                        backgroundColor: 'aliceblue',
                        ':hover': {
                            '> .MuiOutlinedInput-notchedOutline': {
                                borderColor: '#3f51b5 !important'
                            }
                        }
                    }
                }
            ]
        }
    }
});

export default MUITheme;
