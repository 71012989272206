import { FormControl, FormHelperText, TextField } from '@mui/material';
import { IInput } from '../../interfaces/common-interface';

const InputText = ({ id, label, onChange, value, variant, error, helperText, ...MUIprops }: IInput) => {
    return (
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }} variant={variant}>
            <TextField id={id} type={'text'} label={label?.charAt(0)?.toUpperCase() + label.slice(1).toLowerCase()} onChange={onChange} value={value} variant={variant} error={error} {...MUIprops} />
            <FormHelperText error={error} id={`${id}-helper-text`}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default InputText;
