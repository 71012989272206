import { Dispatch, SetStateAction, useEffect, useState } from 'react';

const formatTime = (value: number) => {
    return value < 10 ? `0${value}` : `${value}`;
};

const CountdownTimer = ({ running, setRunning }: { running: boolean; setRunning: Dispatch<SetStateAction<boolean>> }) => {
    const totalMinutes = 1;
    const [totalMilliSeconds, setTotalMilliSeconds] = useState<number>(60 * totalMinutes);
    const [runningTimer, setRunningTimer] = useState<boolean>(running);

    useEffect(() => {
        setRunning(runningTimer);
    }, [runningTimer]);

    useEffect(() => {
        let intervalId: ReturnType<typeof setInterval>;

        if (runningTimer) {
            intervalId = setInterval(() => {
                setTotalMilliSeconds((previousSeconds: number) => {
                    if (previousSeconds <= 0) {
                        clearInterval(intervalId);
                        setRunningTimer(false);
                        return 0;
                    } else {
                        return previousSeconds - 1;
                    }
                });
            }, 1000);
        } else {
            clearInterval(intervalId);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [runningTimer]);

    const minutes = Math.floor(totalMilliSeconds / 60);
    const seconds = totalMilliSeconds % 60;

    return (
        <>
            {formatTime(minutes)} : {formatTime(seconds)}
        </>
    );
};

export default CountdownTimer;
