//Import all translation files
import changePasswordFormEn from './translation/en/change-password-form.json';
import commonEn from './translation/en/common.json';
import forgotPasswordFormEn from './translation/en/forgot-password-form.json';
import loginFormEn from './translation/en/login-form.json';
import logoutEn from './translation/en/logout.json';
import otpEn from './translation/en/otp-form.json';
import changePasswordFormEs from './translation/es/change-password-form.json';
import commonEs from './translation/es/common.json';
import forgotPasswordFormEs from './translation/es/forgot-password-form.json';
import loginFormEs from './translation/es/login-form.json';
import logoutEs from './translation/es/logout.json';
import otpEs from './translation/es/otp-form.json';
import changePasswordFormIt from './translation/it/change-password-form.json';
import commonIt from './translation/it/common.json';
import forgotPasswordFormIt from './translation/it/forgot-password-form.json';
import loginFormIt from './translation/it/login-form.json';
import logoutIt from './translation/it/logout.json';
import otpIt from './translation/it/otp-form.json';

const resources = {
    en: {
        common: commonEn,
        changePasswordForm: changePasswordFormEn,
        forgotPasswordForm: forgotPasswordFormEn,
        loginForm: loginFormEn,
        logout: logoutEn,
        otp: otpEn
    },
    it: {
        common: commonIt,
        forgotPasswordForm: forgotPasswordFormIt,
        loginForm: loginFormIt,
        changePasswordForm: changePasswordFormIt,
        logout: logoutIt,
        otp: otpIt
    },
    es: {
        common: commonEs,
        changePasswordForm: changePasswordFormEs,
        forgotPasswordForm: forgotPasswordFormEs,
        loginForm: loginFormEs,
        logout: logoutEs,
        otp: otpEs
    }
};
export default resources;
