import { gql } from '@apollo/client';

export const LOGIN_QUERY = gql`
    query login($username: String!, $password: String!) {
        signin(username: $username, password: $password) {
            AuthenticationResult {
                TokenType
                RefreshToken
                IdToken
                ExpiresIn
                AccessToken
            }
            ChallengeName
            ChallengeParameters {
                USER_ID_FOR_SRP
                requiredAttributes
                userAttributes
            }
            Session
            metadata {
                attempts
                cfId
                extendedRequestId
                httpStatusCode
                requestId
                totalRetryDelay
            }
        }
    }
`;

export const GLOBALSIGNOUT_QUERY = gql`
    query GlobalSignout($accessToken: String!) {
        globalSignout(accessToken: $accessToken) {
            metadata {
                httpStatusCode
                requestId
                extendedRequestId
                cfId
                attempts
                totalRetryDelay
            }
            errors {
                message
                name
                metadata {
                    httpStatusCode
                    requestId
                    extendedRequestId
                    cfId
                    attempts
                    totalRetryDelay
                }
            }
        }
    }
`;

export const RESPONDTOAUTHCHALLENGE_CHANGEPASSWORD_QUERY = gql`
    query RespondToAuthChallenge($req: ChallengeParams) {
        respondToAuthChallenge(req: $req) {
            AuthenticationResult {
                TokenType
                RefreshToken
                IdToken
                ExpiresIn
                AccessToken
            }
            metadata {
                attempts
                cfId
                extendedRequestId
                httpStatusCode
                requestId
                totalRetryDelay
            }
            ChallengeParameters
        }
    }
`;

export const CHANGEPASSWORD_QUERY = gql`
    query changePassword($accessToken: String!, $oldPassword: String!, $newPassword: String!) {
        changePassword(accessToken: $accessToken, oldPassword: $oldPassword, newPassword: $newPassword) {
            AuthenticationResult {
                TokenType
                RefreshToken
                IdToken
                ExpiresIn
                AccessToken
            }
            ChallengeName
            ChallengeParameters {
                USER_ID_FOR_SRP
                requiredAttributes
                userAttributes
            }
            Session
            metadata {
                attempts
                cfId
                extendedRequestId
                httpStatusCode
                requestId
                totalRetryDelay
            }
        }
    }
`;

export const REFRESH_TOKEN_QUERY = gql`
    query RefreshToken($refreshTokenReq: String!) {
        refreshToken(refreshTokenReq: $refreshTokenReq) {
            AuthenticationResult {
                AccessToken
                ExpiresIn
                IdToken
                RefreshToken
                TokenType
            }
            ChallengeName
            ChallengeParameters {
                USER_ID_FOR_SRP
                requiredAttributes
                userAttributes
            }
        }
    }
`;
