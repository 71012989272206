import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import LoginForm from '../components/auth/login-form';
import { LOGIN_QUERY } from '../graphql/auth';
import { AppDispatch, useAppDispatch, useTypedSelector } from '../store';
import { AuthResult, setAuthData } from '../store/auth/auth-slice';
import { RootState } from '../store/reducers';
import { isValidToken } from '../utils/check-token';

const LoginAuth: React.FunctionComponent<any> = () => {
    const navigate = useNavigate();
    const [login, { loading, error, data }] = useLazyQuery(LOGIN_QUERY);
    const dispatch: AppDispatch = useAppDispatch();
    const auth = useTypedSelector((state: RootState) => state.auth);

    useEffect(() => {
        const isLoggedUser: boolean = (auth.info?.AccessToken && isValidToken(auth.info?.AccessToken)) || false;
        // TODO Mapping possible challenge type
        const isChallengeChangePassword: boolean = (auth.challenge && auth.challenge?.type === 'NEW_PASSWORD_REQUIRED') || false;
        if (isChallengeChangePassword) {
            navigate('/cambio-password');
        } else if (isLoggedUser) {
            navigate('/');
        }
    }, [auth.info?.AccessToken, auth.challenge?.type]);

    const handleLogin = async (username: string, password: string) => {
        try {
            const response = await login({
                variables: { username, password }
            });
            const signin: AuthResult = response.data?.signin;

            if (signin) {
                await dispatch(setAuthData(signin));
            }
        } catch (error_) {
            console.error('Errore:', error_);
        }
    };
    return <LoginForm queryError={error} loading={loading} handleSubmit={(username: string, password: string) => handleLogin(username, password)} />;
};

export default LoginAuth;
