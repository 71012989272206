import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { FLUSH, PAUSE, PERSIST, PURGE, Persistor, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
// import thunk from 'redux-thunk';

// Middleware for logs in dev enviroment only
// const middlewares = [thunk];

export type RootState = ReturnType<typeof rootReducer>;
// redux persist configuration
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'] // the reducer objects to persist
};

const persistStateReducer = persistReducer<RootState>(persistConfig, rootReducer);

// create redux store
export const store = configureStore({
    reducer: persistStateReducer,
    devTools: { trace: true, traceLimit: 25 },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Ignore actions from redux-persist
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
            }
        })
});
export const persistor: Persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector as TypedUseSelectorHook<RootState>;
