import './style.css';

const PasswordStrenght = ({ passwordStrenght }: { passwordStrenght: number }) => {
    let level: string;
    switch (passwordStrenght) {
        case 1: {
            level = 'weak';
            break;
        }
        case 2:
        case 3: {
            level = 'medium';
            break;
        }
        case 4: {
            level = 'high';
            break;
        }
        default: {
            level = '';
            break;
        }
    }

    return (
        <div className={`passwordStrengthBlock ${level}`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};

export default PasswordStrenght;
