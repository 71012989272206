import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Button, Checkbox, FormControlLabel, Grid, Link, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import InputPassword from '../../common/input-password';
import PasswordStrenght from '../../common/password-strenght';

const ChangePasswordForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [passwordStrenghtLevel, setPasswordStrenghtLevel] = useState<number>(0);
    const [ruleMinimumCharLength, setRuleMinimumCharLength] = useState<boolean>(false);
    const [ruleCaseSensitive, setRuleCaseSensitive] = useState<boolean>(false);
    const [ruleSpecialChar, setRuleSpecialChar] = useState<boolean>(false);
    const [ruleNumber, setRuleNumber] = useState<boolean>(false);

    const clearForm = () => {
        setPassword(undefined);
    };

    useEffect(() => {
        const regexpLowerUpperCase = /^(?=.*[a-z])(?=.*[A-Z]).+$/;
        const regexpSpecialChar = /[^\d\sA-Za-z]/;
        const regexNumber = /\d/;
        const passwordToCheck: string = password ?? '';

        setRuleMinimumCharLength(passwordToCheck.length >= 8);
        setRuleCaseSensitive(regexpLowerUpperCase.test(passwordToCheck));
        setRuleSpecialChar(regexpSpecialChar.test(passwordToCheck));
        setRuleNumber(regexNumber.test(passwordToCheck));
    }, [password]);

    useEffect(() => {
        const strengthLevel = [ruleMinimumCharLength, ruleCaseSensitive, ruleSpecialChar, ruleNumber].filter((value) => value === true).length;
        setPasswordStrenghtLevel(strengthLevel);
    }, [ruleMinimumCharLength, ruleCaseSensitive, ruleSpecialChar, ruleNumber]);

    return (
        <Grid container direction="row" flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-around'} width={'100vw'} sx={{ maxWidth: '1920px', minHeight: '70vh' }}>
            <Grid item md={12} xs={12} style={{ maxWidth: '380px' }}>
                <Link
                    component="button"
                    variant="body2"
                    underline="none"
                    onClick={() => {
                        navigate('/login');
                    }}
                >
                    <Grid container alignItems={'center'}>
                        <ArrowBackIosNewOutlinedIcon fontSize="small" color="primary" /> {t('backToLogin', { ns: 'changePasswordForm' })}
                    </Grid>
                </Link>
                <h1
                    style={{
                        backgroundColor: '#FFF',
                        fontSize: '18px',
                        marginBottom: '32px',
                        color: '#1C334C',
                        fontWeight: 600
                    }}
                >
                    {t('title', { ns: 'changePasswordForm' })}
                </h1>
                <div style={{ marginBottom: '24px' }}>{t('subtitle', { ns: 'changePasswordForm' })}</div>

                <form>
                    <Grid container spacing={0} direction="column" gap={'16px'}>
                        <Grid item md={12} xs={12}>
                            <InputPassword
                                id="password"
                                label={t('password', { ns: 'changePasswordForm' })}
                                onChange={(event: React.ChangeEvent) => {
                                    const { target } = event;
                                    if (target) setPassword((target as HTMLButtonElement).value || '');
                                }}
                                variant="outlined"
                                value={password ?? ''}
                                fullWidth={true}
                            />
                            <PasswordStrenght passwordStrenght={passwordStrenghtLevel} />
                            <ul
                                style={{
                                    listStyle: 'none',
                                    padding: 0
                                }}
                            >
                                <li>
                                    <FormControlLabel
                                        checked={ruleMinimumCharLength}
                                        control={<Checkbox defaultChecked={ruleMinimumCharLength} />}
                                        label={t('rules.minimumCharLength', { ns: 'changePasswordForm' })}
                                    />
                                </li>
                                <li>
                                    <FormControlLabel
                                        checked={ruleCaseSensitive}
                                        control={<Checkbox defaultChecked={ruleCaseSensitive} />}
                                        label={t('rules.caseSensitive', { ns: 'changePasswordForm' })}
                                    />
                                </li>
                                <li>
                                    <FormControlLabel checked={ruleSpecialChar} control={<Checkbox defaultChecked={ruleSpecialChar} />} label={t('rules.specialChar', { ns: 'changePasswordForm' })} />
                                </li>
                                <li>
                                    <FormControlLabel checked={ruleNumber} control={<Checkbox defaultChecked={ruleNumber} />} label={t('rules.number', { ns: 'changePasswordForm' })} />
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </form>

                <Grid sx={{ width: '100%' }} container direction="row" justifyContent="flex-end">
                    <Grid>{/* <UserResetButton  /> */}</Grid>
                </Grid>

                <Grid container direction="row" sx={{ marginTop: '32px', width: '100%' }} flexGrow={1} justifyContent={'space-between'}>
                    <Grid item xs={6}>
                        <Button variant="outlined" id="userSignInReset" type="button" onClick={() => clearForm()}>
                            {t('buttons.reset', { ns: 'changePasswordForm' })}
                        </Button>
                    </Grid>
                    <Grid item xs={'auto'} justifySelf={'flex-end'}>
                        <Tooltip title={passwordStrenghtLevel < 3 && t('buttons.tooltipDisabledMessage', { ns: 'changePasswordForm' })} arrow>
                            <span>
                                <Button disabled={passwordStrenghtLevel < 3} variant="contained" id="userSignInSubmit" type="button" onClick={() => console.log('TODO')}>
                                    {t('buttons.send', { ns: 'changePasswordForm' })}
                                </Button>
                            </span>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ChangePasswordForm;
