import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';

import { InputLabel, OutlinedInput } from '@mui/material';
import { IInput } from '../../interfaces/common-interface';

const InputPassword = ({ error, id, label, onChange, value, variant, helperText, ...MUIprops }: IInput) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <FormControl sx={{ m: 1, width: '100%', margin: 0 }}>
            <InputLabel htmlFor={id}>{label?.charAt(0)?.toUpperCase() + label.slice(1).toLowerCase()}</InputLabel>

            <OutlinedInput
                variant={variant}
                label={label?.charAt(0)?.toUpperCase() + label.slice(1).toLowerCase()}
                id={id}
                type={showPassword ? 'text' : 'password'}
                onChange={(event: React.ChangeEvent) => onChange(event)}
                value={value}
                error={error}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                {...MUIprops}
            />
            <FormHelperText error={error} id={`${id}-helper-text`}>
                {helperText}
            </FormHelperText>
        </FormControl>
    );
};

export default InputPassword;
