import { changeLanguage } from 'i18next';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LayoutComponent from '../components/layout';
import ProtectedRoute from '../routesList/protected-route';
import AboutPage from '../views/About';
import ChangePassword from '../views/change-password';
import ForgotPassword from '../views/forgot-password';
import HomePage from '../views/home';
import LoginAuth from '../views/login-auth';
import Otp from '../views/otp';

// Definisci un array di rotte
const routes = [
    {
        path: '/',
        element: (
            <ProtectedRoute>
                <HomePage />
            </ProtectedRoute>
        )
    },
    {
        path: '/login',
        element: <LoginAuth />
    },
    {
        path: '/password-dimenticata',
        element: <ForgotPassword />
    },
    {
        path: '/cambio-password',
        element: <ChangePassword />
    },
    {
        path: '/otp',
        element: <Otp />
    },
    {
        path: '/about',
        element: <AboutPage />,
        children: [
            {
                path: ':number',
                element: <AboutPage />
            },
            {
                path: '/about/pippo',
                element: <LayoutComponent />
            }
        ]
    },
    {
        path: '/layout',
        element: <LayoutComponent />,
        children: [
            {
                path: ':number',
                element: <AboutPage />
            }
        ]
    }
];

const RoutesList = () => {
    useEffect(() => {
        /* i18next prepare to change language maybe set in localstorage, when change update
         */
        const langAbbr: string = (window.localStorage.i18nextLng || window.navigator.language.split('-')[0]) as string;
        void changeLanguage(langAbbr);
    }, []);

    return (
        <Router>
            <Routes>
                {/* Utilizza map per creare le rotte dal tuo array */}
                {routes.map((route, index) => (
                    <Route key={index} path={route.path} element={route.element}>
                        {/* Rotte annidate (se presenti) */}
                        {route.children && route.children.map((childRoute, childIndex) => <Route key={childIndex} path={childRoute.path} element={childRoute.element} />)}
                    </Route>
                ))}
            </Routes>
        </Router>
    );
};

export default RoutesList;
