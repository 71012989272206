import { QueryResult, useLazyQuery } from '@apollo/client';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { GLOBALSIGNOUT_QUERY } from '../../../graphql/auth';
import { AppDispatch, RootState, useAppDispatch, useTypedSelector } from '../../../store';
import { setAuthData } from '../../../store/auth/auth-slice';

const Logout = () => {
    interface StateAlert {
        message: string;
        open: boolean;
        vertical: string;
        horizontal: string;
    }
    const [stateAlert, setStateAlert] = useState<StateAlert>({
        message: '',
        open: false,
        vertical: 'top',
        horizontal: 'right'
    });
    const { vertical, horizontal, open } = stateAlert;
    const navigate = useNavigate();

    const [GlobalSignout, { loading, error, data }] = useLazyQuery(GLOBALSIGNOUT_QUERY);
    const dispatch: AppDispatch = useAppDispatch();
    const auth = useTypedSelector((state: RootState) => state.auth);

    const manageErrors = () => {};

    const handleClick = async () => {
        try {
            const currentAccessToken = auth.info?.AccessToken;
            if (!currentAccessToken) return;
            const response: QueryResult<any> = await GlobalSignout({
                variables: { accessToken: currentAccessToken }
            });

            if (response.data?.globalSignout?.metadata?.httpStatusCode === 200 || response?.data?.globalSignout?.errors?.[0]?.name === 'NotAuthorizedException') {
                // no error, logout success
                await dispatch(setAuthData({}));
            } else if (response && response?.data?.globalSignout?.errors?.length > 0) {
                setStateAlert({
                    ...stateAlert,
                    open: true,
                    message: response?.data?.globalSignout?.errors
                });
            }
        } catch (error_) {
            debugger;
            console.error('Errore:', error_);
        }
    };

    const handleCloseAlert = () => {
        setStateAlert({
            ...stateAlert,
            open: !open
        });
    };
    return auth.info?.AccessToken ? (
        <>
            <Button
                onClick={() => {
                    handleClick()
                        .then(() => navigate('/login'))
                        .catch((error_) => console.log(error_));
                }}
                variant="outlined"
                startIcon={<LogoutIcon />}
            >
                Logout
            </Button>
            <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} onClose={handleCloseAlert} message="I love snacks" key={vertical + horizontal} />
        </>
    ) : (
        <></>
    );
};

export default Logout;
