import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { RootState, useTypedSelector } from '../store';
import { isValidToken } from '../utils/check-token';

const ProtectedRoute = ({ children }: { children: ReactNode }) => {
    const auth = useTypedSelector((state: RootState) => state.auth);
    const isLoggedUser: boolean = (auth.info?.AccessToken && isValidToken(auth.info?.AccessToken)) || false;

    if (!isLoggedUser) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};

export default ProtectedRoute;
