import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { ThemeProvider } from '@mui/material';
import LanguageSelector from './components/langSelector';
import i18n from './i18n/i18n';
import RoutesList from './routesList';
import { persistor, store } from './store';
import MUITheme from './theme';

const client = new ApolloClient({
    uri: 'http://localhost:9010',
    cache: new InMemoryCache()
});

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={undefined} persistor={persistor}>
                <ApolloProvider client={client}>
                    <I18nextProvider i18n={i18n}>
                        <Suspense fallback={<div></div>}>
                            <ThemeProvider theme={MUITheme}>
                                <LanguageSelector />
                                <RoutesList />
                            </ThemeProvider>
                        </Suspense>
                    </I18nextProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
}

export default App;
