import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { Alert, Button, CircularProgress, Grid, Link, Snackbar, Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import InputText from '../../common/input-text';

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const loading = false; // TODO
    const [username, setUsername] = useState<string | undefined>(undefined);
    const [openOkSend, setOpenOkSend] = useState<boolean>(false);
    const [isEnabledSubmitButton, setIsEnabledSubmitButton] = useState<boolean>(false);

    const clearForm = () => {
        setUsername(undefined);
    };

    useEffect(() => {
        const regexpEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        const isEnabled: boolean = !username || !regexpEmail.test(username) ? false : true;
        setIsEnabledSubmitButton(isEnabled);
    }, [username]);

    const handleSubmit = () => {
        setUsername(undefined);
        setOpenOkSend(true);
    };

    const handleCloseOkSend = () => {
        setOpenOkSend(false);
    };

    return (
        <>
            <Grid container direction="row" flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-around'} width={'100vw'} sx={{ maxWidth: '1920px', minHeight: '70vh' }}>
                <Grid item md={12} xs={12} style={{ maxWidth: '380px' }}>
                    <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                            navigate('/login');
                        }}
                    >
                        <Grid container alignItems={'center'}>
                            <ArrowBackIosNewOutlinedIcon fontSize="small" color="primary" /> {t('backToLogin', { ns: 'forgotPasswordForm' })}
                        </Grid>
                    </Link>
                    <h1
                        style={{
                            backgroundColor: '#FFF',
                            fontSize: '18px',
                            margin: '24px 0',
                            color: '#1C334C',
                            fontWeight: 600
                        }}
                    >
                        {t('title', { ns: 'forgotPasswordForm' })}
                    </h1>
                    <div style={{ marginBottom: '24px' }}>{t('subtitle', { ns: 'forgotPasswordForm' })}</div>

                    <form>
                        <Grid container spacing={0} direction="column" gap={'16px'}>
                            <Grid item xs={6} width={'100%'}>
                                <InputText
                                    id="email"
                                    variant="outlined"
                                    placeholder="mariorossi@gmail.com"
                                    label={t('username', { ns: 'forgotPasswordForm' })}
                                    onChange={(event: ChangeEvent) => {
                                        const { target } = event;
                                        if (target) {
                                            setUsername((target as HTMLButtonElement).value || '');
                                        }
                                    }}
                                    value={username ?? ''}
                                    fullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </form>

                    <Grid sx={{ width: '100%' }} container direction="row" justifyContent="flex-end">
                        <Grid>{/* <UserResetButton  /> */}</Grid>
                    </Grid>
                    {loading ? (
                        <CircularProgress
                            size={64}
                            sx={{
                                color: 'red',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px'
                            }}
                        />
                    ) : (
                        <Grid container direction="row" sx={{ marginTop: '32px', width: '100%' }} flexGrow={1} justifyContent={'space-between'}>
                            <Grid item xs={6}>
                                <Button variant="outlined" id="userSignInReset" type="button" onClick={() => clearForm()}>
                                    {t('buttons.reset', { ns: 'forgotPasswordForm' })}
                                </Button>
                            </Grid>
                            <Grid item xs={'auto'} justifySelf={'flex-end'}>
                                <Tooltip title={!isEnabledSubmitButton && t('buttons.tooltipDisabledMessage', { ns: 'forgotPasswordForm' })} arrow>
                                    <span>
                                        <Button disabled={!isEnabledSubmitButton} variant="contained" id="userSignInSubmit" type="button" onClick={() => handleSubmit(username)}>
                                            {t('buttons.send', { ns: 'forgotPasswordForm' })}
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Snackbar sx={{ width: 'calc(100% - 50px)' }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={openOkSend} autoHideDuration={6000} onClose={handleCloseOkSend}>
                <Alert onClose={handleCloseOkSend} severity="success" variant="filled" sx={{ width: '100%' }}>
                    {t('snackbar.title', { ns: 'forgotPasswordForm' })}
                    <br />
                    {t('snackbar.subtitle', { ns: 'forgotPasswordForm' })}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ForgotPasswordForm;
