import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import i18next, { changeLanguage } from 'i18next';
import { useState } from 'react';

const supportedLanguage = ['en', 'es', 'it'];

// !!! the parent have to set position relative
const LanguageSelector = () => {
    const [selectedLang, setSelectedLang] = useState<string>(i18next.language.split('-')[0] || (window.localStorage.i18nextLng as string));

    function onChangeLanguage(lang: string) {
        if (lang) {
            void changeLanguage(lang);
            setSelectedLang(lang);
        }
    }

    return (
        <div
            style={{
                position: 'absolute',
                right: '7.3%'
            }}
        >
            <FormControl variant="standard" sx={{ m: 1, minWidth: 'fit-content' }}>
                <Select labelId="language-selector-label" id="language-selector" value={selectedLang} label="Language" autoWidth={true} sx={{ width: 'max-content', textAlign: 'right' }}>
                    {supportedLanguage.map((lang: string) => {
                        return (
                            <MenuItem key={`lang_${lang}`} onClick={() => onChangeLanguage(lang)} value={lang}>
                                {lang.toUpperCase()}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default LanguageSelector;
