import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InfoState {
    AccessToken: string | null;
    ExpiresIn: number | null;
    IdToken: string | null;
    RefreshToken: string | null;
    TokenType: string | null;
}

interface ChallengeState {
    type: string;
    challengeData: ChallengeParametersType;
    session: String;
}

type AuthenticationResultType = {
    __typename: string;
    TokenType: string;
    RefreshToken: string;
    IdToken: string;
    ExpiresIn: number;
    AccessToken: string;
};

type ChallengeParametersType = {
    __typename: string;
    USER_ID_FOR_SRP: string;
    requiredAttributes: string;
    userAttributes: string;
};

export interface AuthResult {
    AuthenticationResult: AuthenticationResultType | null;
    ChallengeName: string | null;
    ChallengeParameters: ChallengeParametersType | null;
    Session: string | null;
    metadata: null;
}

interface AuthState {
    info: InfoState | undefined;
    challenge: ChallengeState | undefined;
}

const initialState: AuthState = {
    info: undefined,
    challenge: undefined
};

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setAuthData: (_state, action: PayloadAction<AuthResult | undefined>) => {
            return {
                info: action.payload?.AuthenticationResult
                    ? {
                          AccessToken: action.payload.AuthenticationResult?.AccessToken,
                          ExpiresIn: action.payload.AuthenticationResult?.ExpiresIn,
                          IdToken: action.payload.AuthenticationResult?.IdToken,
                          RefreshToken: action.payload.AuthenticationResult?.RefreshToken,
                          TokenType: action.payload.AuthenticationResult?.TokenType
                      }
                    : undefined,
                challenge: action.payload?.ChallengeName
                    ? {
                          type: action.payload?.ChallengeName,
                          challengeData: action.payload?.ChallengeParameters,
                          session: action.payload?.Session
                      }
                    : undefined
            };
        }
    }
});

export const { setAuthData } = authSlice.actions;
