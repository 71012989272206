import React from 'react';
import RefreshTokenButton from '../components/auth/login-form/refresh-token-button';
import TokenExpirationCounter from '../components/auth/login-form/token-expiration-counter';
import Logout from '../components/auth/logout';
import { useTypedSelector } from '../store';
import { RootState } from '../store/reducers';
import { isValidToken } from '../utils/check-token';

const HomePage: React.FunctionComponent = () => {
    const auth = useTypedSelector((state: RootState) => state.auth);

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', margin: '20px' }}>
            <Logout />
            <div>Utente loggato: {auth.info?.AccessToken && isValidToken(auth.info.AccessToken) ? 'TRUE' : 'FALSE'}</div>
            <br />
            <TokenExpirationCounter token={auth.info?.AccessToken || ''} />
            <div style={{ width: '100%', wordBreak: 'break-word' }}>{auth.info?.AccessToken ?? 'FK'}</div>
            <RefreshTokenButton />
        </div>
    );
};

export default HomePage;
