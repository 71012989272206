import { Button, CircularProgress, Grid, Link, Tooltip } from '@mui/material';

import { styled } from '@mui/material';
import CountdownTimer from '../../common/countdown-timer';
// eslint-disable-next-line import/no-extraneous-dependencies
import { MuiOtpInput } from 'mui-one-time-password-input';
import { ChangeEvent, ClipboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

const MuiOtpInputStyled = styled(MuiOtpInput)(({ theme }) => ({}));

interface OtpInputProperties {
    handlerInput: (value: ChangeEvent<HTMLInputElement>) => void;
    digitsCount: number;
    value: string;
    isDisabled?: boolean;
    isDisabledInput?: boolean;
    hideInput?: boolean;
}
const REG_EX_DIGITS = new RegExp(/^\d+$/);

function OtpForm() {
    const loading = false;
    const navigate = useNavigate();
    const [otp, setOtp] = useState<string>('');
    const [pasted, setPasted] = useState<string | undefined>(undefined);
    const [runningResendTimer, setRunningResendTimer] = useState(true);
    const { t } = useTranslation(['opt']);

    const clearForm = () => {
        setPassword(undefined);
        setUsername(undefined);
    };

    const handleChange = (newValue: string) => {
        const userValue: string = pasted || newValue;

        console.log('pasted' + pasted);
        setOtp(userValue);
        setPasted(undefined);
    };

    const handleComplete = (finalValue: string) => {
        console.log(finalValue);
    };

    const matchIsNumeric = (text: string) => {
        const isNumber = typeof text === 'number';
        // const isString = matchIsString(text);
        return (isNumber || /*isString &&*/ text !== '') && !isNaN(Number(text));
    };

    const validateChar = (value: string, index: number) => {
        return matchIsNumeric(value);
    };

    return (
        <Grid container direction="row" flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-around'} width={'100vw'} sx={{ maxWidth: '1920px', minHeight: '70vh' }}>
            <Grid item md={12} xs={12} style={{ maxWidth: '380px' }}>
                <h1
                    style={{
                        backgroundColor: '#FFF',
                        fontSize: '18px',
                        marginBottom: '32px',
                        color: '#1C334C',
                        fontWeight: 600
                    }}
                >
                    {t('title', { ns: 'otp' })}
                </h1>
                <form>
                    <Grid container spacing={0} direction="column" gap={'16px'}>
                        <Grid item xs={12} width={'100%'}>
                            <MuiOtpInput
                                onPasteCapture={(event: ClipboardEventHandler<HTMLDivElement> | undefined) => setPasted(event.clipboardData.getData('text').trim())}
                                value={otp}
                                onChange={handleChange}
                                onComplete={handleComplete}
                                length={5}
                                validateChar={validateChar}
                                autoFocus
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container justifyContent="flex-end">
                    <Tooltip title={!otp && t('resendCodeTooltip', { ns: 'otp' })} arrow>
                        <span>
                            {runningResendTimer && <CountdownTimer running={runningResendTimer} setRunning={setRunningResendTimer} />}{' '}
                            <Link
                                disabled={runningResendTimer}
                                component="button"
                                variant="body2"
                                underline="none"
                                onClick={() => {
                                    //TODO
                                    console.log('toast invio');
                                }}
                            >
                                {t('resendCodeLink', { ns: 'otp' })}
                            </Link>
                        </span>
                    </Tooltip>
                </Grid>

                {loading ? (
                    <CircularProgress
                        size={64}
                        sx={{
                            color: 'red',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                        }}
                    />
                ) : (
                    <Grid container direction="row" sx={{ marginTop: '32px', width: '100%' }} flexGrow={1} justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <Button variant="outlined" id="userSignInReset" type="button" onClick={() => clearForm()}>
                                {t('buttons.reset', { ns: 'otp' })}
                            </Button>
                        </Grid>
                        <Grid item xs={'auto'} justifySelf={'flex-end'}>
                            <Tooltip title={!otp && t('buttons.tooltipDisabledMessage', { ns: 'otp' })} arrow>
                                <span>
                                    <Button disabled={!otp} variant="contained" id="userSignInSubmit" type="button" onClick={() => handleSubmit(username, password)}>
                                        {t('buttons.send', { ns: 'otp' })}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}

export default OtpForm;
