import * as i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import postProcessor from 'i18next-sprintf-postprocessor';
import { initReactI18next } from 'react-i18next';
import resources from './resources';
//---Using different namespaces

void i18next
    .use(initReactI18next)
    .use(ChainedBackend)
    .use(LanguageDetector)
    .use(postProcessor)
    .init({
        debug: true,
        resources,
        keySeparator: '.',
        backend: {
            backends: [LocalStorageBackend, HttpBackend],
            backendOptions: [],
            cacheHitMode: 'refreshAndUpdateStore'
        },
        interpolation: {
            escapeValue: false
        },
        react: {
            bindI18nStore: 'added' // this way, when the HttpBackend delivers new translations (thanks to refreshAndUpdateStore), the UI gets updated
        }
    });

export { default } from 'i18next';
