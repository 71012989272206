import { Button, Grid, Link, Tooltip } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import InputPassword from '../../common/input-password';
import InputText from '../../common/input-text';

interface IUserSignInFormProperties {
    handleSubmit: (username: string, password: string) => void;
    // loading: boolean;
    // queryError: ApolloError | undefined;
}

const LoginForm = ({ handleSubmit /*, loading */ }: IUserSignInFormProperties) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const loading = false; // TODO
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [username, setUsername] = useState<string | undefined>(undefined);

    const clearForm = () => {
        setPassword(undefined);
        setUsername(undefined);
    };

    return (
        <Grid container direction="row" flexWrap={'wrap'} alignItems={'center'} justifyContent={'space-around'} width={'100vw'} sx={{ maxWidth: '1920px', minHeight: '70vh' }}>
            <Grid item md={12} xs={12} style={{ maxWidth: '380px' }}>
                <h1
                    style={{
                        backgroundColor: '#FFF',
                        fontSize: '18px',
                        marginBottom: '32px',
                        color: '#1C334C',
                        fontWeight: 600
                    }}
                >
                    {t('title', { ns: 'loginForm' })}
                </h1>
                <form>
                    <Grid container spacing={0} direction="column" gap={'16px'}>
                        <Grid item xs={6} width={'100%'}>
                            <InputText
                                id="username"
                                label={t('username', { ns: 'loginForm' })}
                                onChange={(event: ChangeEvent) => {
                                    const { target } = event;
                                    if (target) {
                                        setUsername((target as HTMLButtonElement).value.trim() || '');
                                    }
                                }}
                                value={username ?? ''}
                                variant="outlined"
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputPassword
                                id="password"
                                label={t('password', { ns: 'loginForm' })}
                                onChange={(event: React.ChangeEvent) => {
                                    const { target } = event;
                                    if (target) setPassword((target as HTMLButtonElement).value.trim() || '');
                                }}
                                value={password ?? ''}
                                variant="outlined"
                                fullWidth={true}
                            />
                        </Grid>
                    </Grid>
                </form>
                <Grid container justifyContent="flex-end">
                    <Link
                        component="button"
                        variant="body2"
                        underline="none"
                        onClick={() => {
                            navigate('/password-dimenticata');
                        }}
                    >
                        {t('forgotPasswordLink', { ns: 'loginForm' })}
                    </Link>
                </Grid>

                {loading ? (
                    <CircularProgress
                        size={64}
                        sx={{
                            color: 'red',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px'
                        }}
                    />
                ) : (
                    <Grid container direction="row" sx={{ marginTop: '32px', width: '100%' }} flexGrow={1} justifyContent={'space-between'}>
                        <Grid item xs={6}>
                            <Button variant="outlined" id="userSignInReset" type="button" onClick={() => clearForm()}>
                                {t('buttons.reset', { ns: 'loginForm' })}
                            </Button>
                        </Grid>
                        <Grid item xs={'auto'} justifySelf={'flex-end'}>
                            <Tooltip title={(!password || !username) && t('buttons.tooltipDisabledMessage', { ns: 'loginForm' })} arrow>
                                <span>
                                    <Button disabled={!password || !username} variant="contained" id="userSignInSubmit" type="button" onClick={() => handleSubmit(username, password)}>
                                        {t('buttons.signin', { ns: 'loginForm' })}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

export default LoginForm;
